<template>
  <div>
    <productModal
        v-if="showModal"
        @showModal = 'changeShowModal'
        :product = product
        @search = 'search'
    />
    <p v-if="rows && rows.meta" class="pagination__text">
      {{ rows.meta.from }}-{{ rows.meta.to }} из
      {{ rows.meta.total }}
    </p>
    <b-pagination
        v-model="currentPage"
        v-if="rows && rows.meta"
        :total-rows="rows.meta.total"
        :per-page="perPage"
        aria-controls="my-table"
        :hide-goto-end-buttons="true"
        :limit="1"
        class="pagination"
        @input="changePage()"
    >
      <template #prev-text>
        <img
            class="pagination__btn-icon pagination__btn-icon_prev"
            src="@/assets/png/database-icon-active.png"
            alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>

    <div class="table">
      <b-table
          v-if="rows"
          id="my-table"
          class="table__box"
          :borderless="true"
          :items="rows.stock"
          :fields="fields"
          small
      >
        <template #thead-top>
          <b-tr>
            <b-th class="table-title">На складе</b-th>
          </b-tr>
        </template>
        <template #cell(category)="data">
          <div>
            <span class="product-category">{{ data.item.category }}</span>
          </div>
        </template>
        <template #cell(condition)="data">
          <span :class="conditionStyles(data.item.condition)">
            {{ condition(data.item.condition) }}
          </span>
        </template>
        <template #cell(action)="data">
          <a class="table-btn" v-if="data.item.character_purchase === 'возвратный'">
            <b-icon
                v-show="data"
                @click="changeShowModal(data.item)"
                icon="arrow-left-right"
                variant="primary"
                aria-hidden="true"
                class="mr-2"
            />
          </a>
        </template>
      </b-table>
    </div>
    <div class="table">
      <b-table
          v-if="rows"
          id="my-table"
          class="table__box"
          :borderless="true"
          :items="rows.established"
          :fields="fields_established"
          small
      >
        <template #thead-top>
          <b-tr>
            <b-th class="table-title">Установлено</b-th>
          </b-tr>
        </template>
        <template #cell(category)="data">
          <div>
            <span class="product-category">{{ data.item.category }}</span>
          </div>
        </template>
        <template #cell(condition)="data">
          <span :class="conditions[data.item.condition]">
            {{ condition(data.item.condition) }}
          </span>
        </template>
        <template #cell(action)="data">
          <a class="table-btn" v-if="data.item.character_purchase === 'возвратный'">
            <b-icon
                v-show="data"
                @click="changeShowModal(data.item)"
                icon="arrow-left-right"
                aria-hidden="true"
                class="mr-2"
                variant="primary"
            />
          </a>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  components: {
    productModal: () => ({
      component: import("./productModal"),
    }),
  },
  data () {
    return {
      currentPage: 1,
      perPage: 10,
      showModal: false,
      product: {},
      fields: [
        {
          key: "name",
          label: "Наименование",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "category",
          label: "Категория",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "quantity",
          label: "Количество",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "description",
          label: "Описание",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "condition",
          label: "Состояние",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "mileage",
          label: "Пробег",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      fields_established: [
        {
          key: "name",
          label: "Наименование",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "category",
          label: "Категория",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "mileage",
          label: "Пробег",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "description",
          label: "Описание",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "transport",
          label: "Автомобиль",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      conditionStyle: [],
    }
  },
  computed: {
    rows() {
      if (this.productList) {
        this.$emit("spinner");
        return this.productList
      }
      return 3;
    },
    ...mapState(["productList", "textFilter", "dateFilter", "typeFilter"]),

  },
  async created() {
    if (this.$route.query.page != undefined && this.$route.query.page != 1) {
      this.currentPage = this.$route.query.page
    }
    await this.search();
  },
  methods: {
    conditionStyles(value) {
      if (value === "новое") {
        return "table-btn__status";
      }
      else if (value === "Б/У") {
        return "table-btn__status-pending";
      }
    },
    condition(value) {
      if (value === "новое") {
        return "Новое";
      }
      else if (value === "Б/У") {
        return "Б/У";
      }
    },
    changeShowModal (product) {
      this.showModal = !this.showModal
      this.product = product
    },
    async search(){
      await this.$store.dispatch("getProductList", {
        page: this.currentPage,
        search: this.textFilter,
      })
    },
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
  },
  watch: {
    async currentPage() {
      await this.search();
    },
    async textFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async dateFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async typeFilter() {
      this.currentPage = 1;
      await this.search();
    },
  },

}
</script>

<style scoped>
.table {
  width: 100%;
}
.table-title{
  padding-left: 20px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 400
}
.pagination,
.pagination__text {
  position: absolute;
  top: -164px;
  right: 50px;
}
.pagination__text {
  right: 128px;
  top: -154px;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.table-btn__status-pending {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FFF4D2;
  color: #FF9E0D;
}
.table-btn__status-busy {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FBD2CE;
  color: #E74C3C;
}
.product-category {
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  background: #F2F2F8;
}
</style>